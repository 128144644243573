// src/components/panels/GraphicsPanel.js

import React, { useState, useEffect, useRef } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { MdUpload } from 'react-icons/md';

const fabric = require('fabric');

const GraphicsPanel = ({ editor, uploadImage }) => {
  const graphics = [
    { id: 1, name: 'olive-wreath', image: '/graphics/olive-wreath-icon.svg', type: "svg" },
    { id: 2, name: 'ribbon', image: '/graphics/ribbon.svg', type: "svg" },
    { id: 3, name: 'graduation-cap', image: '/graphics/graduation-cap.svg', type: "svg" },
    { id: 4, name: 'circle', image: '/graphics/circle.svg', type: "svg" },
    { id: 5, name: 'square', image: '/graphics/square.svg', type: "svg" },
    { id: 6, name: 'line', image: '/graphics/line.svg', type: "line" },
    { id: 7, name: 'Template 3', image: '/graphics/horizontal-line.svg', type: "svg" },
    { id: 1, name: 'olive-wreath', image: '/graphics/olive-wreath-icon.svg', type: "svg" },

  ];

  const [graphicElements, setGraphicElements] = useState([]);

  const addImage = ({ url = null, name = "Image" }) => {
    if (url) {
      const image = fabric.FabricImage.fromURL(url)
      //Go through all the objects and find the image object with id backgroundImage 

      image.then(function (img) {
        img.name = name;
        editor.canvas.add(img)
      })
      editor.canvas.renderAll();
    }
  }
  const addSvgImage = async (svg) => {
    const { objects, options } = await fabric.loadSVGFromURL(svg.image)
    const svgGraphic = fabric.util.groupSVGElements(objects, options);

    //Get the index 
    const predictedIndex = editor.canvas.getObjects().length;


    // svgGraphic.set('fill', '#000000'); // Hex code
    svgGraphic.set('name', `${svg.name}-${predictedIndex}`);
    editor.canvas.add(svgGraphic);
    editor.canvas.centerObject(svgGraphic);
    editor.canvas.setActiveObject(svgGraphic);
    editor.canvas.renderAll();
  }
  const uploadImageObject = ({ target: { files } }) => {
    const file = files[0];
    if (!file) return; // Early return if no file

    if (!file.type.startsWith("image/")) {
      console.error("Please upload a valid image file.");
      return;
    }

    const reader = new FileReader();

    reader.onload = ({ target: { result } }) => {
      const formData = new FormData();
      formData.append("file", file);
      uploadImage({ formData, addImage, addSvgImage });
    };

    reader.onerror = (error) => {
      console.error("Error reading file:", error);
    };

    reader.readAsDataURL(file);

    // Reset the input field to allow re-uploading the same file
    event.target.value = null;
  };


  useEffect(() => {
    if (!editor) return;
    if (!editor.canvas) return;
    //Get the elements from the editor
    const elements = editor.canvas.getObjects();

    setGraphicElements(() => {
      return elements.filter((element) => element.type !== "i-text" && !element.isQr);
    });


    //Listen to the object added event
    editor.canvas.on('object:added', (e) => {
      const elements = editor.canvas.getObjects();

      setGraphicElements(() => {
        return elements.filter((element) => element.type !== "i-text" && !element.isQr);
      });
    });
    //Listen to the object added event
    editor.canvas.on('object:removed', (e) => {
      const elements = editor.canvas.getObjects();

      setGraphicElements(() => {
        return elements.filter((element) => element.type !== "i-text" && !element.isQr);
      });
    });

  }, [editor]);

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    // Programmatically trigger the click event on the hidden file input
    fileInputRef.current.click();


  };


  return (
    <Tabs defaultIndex={0} key={editor}>
      <TabList>
        <Tab>Elements</Tab>
        <Tab>Library</Tab>
      </TabList>
      <TabPanel>
        {graphicElements.map((object, index) => (
          <div
            key={index}
            className="cursor-pointer flex flex-col bg-white hover:bg-yellow-100 rounded-sm"
            onClick={() => {
              editor.canvas.setActiveObject(object);
              editor.canvas.renderAll();
            }}
          >
            <p className="text-base p-2 font-medium text-gray-800">{object.name}</p>
            {index !== graphicElements.length - 1 && <hr className="mt-4 border-t border-gray-300" />}
          </div>
        ))}
        <div className="mt-4 flex justify-center">
          <input
            key={fileInputRef}
            type="file"
            accept="image/*,image/svg+xml"
            ref={fileInputRef}
            style={{ display: 'none' }} // Hide the default file input
            onChange={(event) => {
              uploadImageObject(event);
            }}
          />

          <button
            onClick={handleButtonClick}
            className="w-full flex items-center justify-center gap-2 px-4 py-2 bg-green-500 text-white font-medium rounded-lg hover:bg-green-600 transition duration-150 shadow-md hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-green-400"
          >
            <MdUpload className="text-lg" />
            Upload
          </button>
        </div>
      </TabPanel>
      <TabPanel>
        <div className="mt-2 p-4 border border-gray-300 rounded-lg bg-white shadow-sm">
          <div className="mt-0 w-full p-2 overflow-y-auto" style={{ maxHeight: '40vh' }}>
            <div className="grid grid-cols-2 gap-5">
              {graphics.map((graphic) => (
                <div
                  draggable={false}
                  key={graphic.id}
                  className="cursor-pointer w-full h-full overflow-hidden rounded-lg shadow-md hover:shadow-xl hover:scale-105 transition-transform duration-200 bg-gray-50 flex flex-col items-center justify-center p-2"
                  onClick={() => {
                    if (graphic.type === "svg") {
                      addSvgImage(graphic)
                    } else {
                      //Image name the name of fabric element
                      if (graphic.type === "line") {
                        const line = new fabric.Line([50, 100, 200, 100], {
                          stroke: 'black',        // Line color
                          strokeWidth: 1.5,         // Line thickness
                          selectable: true,       // Allow selection
                          evented: true,          // Enable event handling
                          padding: 30,            // Expand the clickable/selectable area
                          name: "horizontal-line"
                        });
                        editor.canvas.add(line);
                        editor.canvas.centerObject(line);

                        editor.canvas.renderAll();
                      }
                    }

                  }}
                >
                  <img src={graphic.image} alt={graphic.name} loading="lazy" />
                  <p className="text-xs font-medium text-gray-700 mt-2 text-center">{graphic.name || "Element"}</p>
                </div>
              ))}
            </div>
          </div>

        </div>
      </TabPanel>

    </Tabs >
  );
};

export default GraphicsPanel;
