import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { MdPlusOne } from "react-icons/md";
const fabric = require('fabric');

const QrPanel = ({ editor, addText }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState("certificate");
  const [customLink, setCustomLink] = useState("");
  const [graphicElements, setGraphicElements] = useState([]);


  const addSvgImage = async ({ name }) => {
    const { objects, options } = await fabric.loadSVGFromURL(`/qr-code-icon.svg`)
    const svgGraphic = fabric.util.groupSVGElements(objects, options);

    //Get the index 
    const predictedIndex = editor.canvas.getObjects().length;


    // svgGraphic.set('fill', '#000000'); // Hex code
    svgGraphic.set('name', `${name}-${predictedIndex}`);
    svgGraphic.set('isQr', true);
    svgGraphic.set('qrData', customLink);
    editor.canvas.add(svgGraphic);
    editor.canvas.centerObject(svgGraphic);
    editor.canvas.setActiveObject(svgGraphic);
    editor.canvas.renderAll();
  }

  useEffect(() => {
    if (!editor) return;
    if (!editor.canvas) return;
    //Get the elements from the editor
    const elements = editor.canvas.getObjects();

    setGraphicElements(() => {
      return elements.filter((element) => {
        console.log("Element", element.type);
        return element.isQr;
      });
    });


    //Listen to the object added event
    editor.canvas.on('object:added', (e) => {
      const elements = editor.canvas.getObjects();

      setGraphicElements(() => {
        return elements.filter((element) => element.isQr);
      });
    });
    //Listen to the object added event
    editor.canvas.on('object:removed', (e) => {
      const elements = editor.canvas.getObjects();

      setGraphicElements(() => {
        return elements.filter((element) => element.isQr);
      });
    });

  }, [editor]);

  const handleAddQrCode = () => {
    if (selectedOption === "certificate") {
      addSvgImage({ name: "Certificate Verification" });
      // addText("Certificate verification QR code");
    } else if (selectedOption === "custom") {
      if (!isValidUrl(customLink)) {
        alert("Please enter a valid  link");
        return;
      }
      if (customLink.trim() === "" && !isValidUrl(customLink)) {
        alert("Please enter a valid custom link");
        return;
      }
      addSvgImage({ name: `Custom link QR code: ${customLink}` });

    }
    setShowModal(false);
    setCustomLink("");
    setSelectedOption("certificate");
  };
  const isValidUrl = (url) => {
    // Regular expression to match URLs starting with http or https
    const urlPattern = /^(https?:\/\/)[^\s/$.?#].[^\s]*$/i;

    // Test the input URL against the pattern
    return urlPattern.test(url);
  }
  return (
    <div className="mt-2 p-2 border border-gray-300 bg-white w-full shadow-md rounded-lg">
      <div className="space-y-4 overflow-y-auto" style={{ maxHeight: "40vh" }}>
        {graphicElements.map((object, index) => (
          <div
            key={index}
            className="cursor-pointer flex flex-col bg-white hover:bg-yellow-100 rounded-sm"
            onClick={() => {
              editor.canvas.setActiveObject(object);
              editor.canvas.renderAll();
            }}
          >
            <p className="text-base p-2 font-medium text-gray-800">{object.name}</p>
            {index !== graphicElements.length - 1 && <hr className="mt-4 border-t border-gray-300" />}
          </div>
        ))}
      </div>
      <div className="mt-4 flex justify-center">
        <button
          onClick={() => setShowModal(true)}
          className="w-full flex items-center justify-center gap-2 px-4 py-2 bg-green-500 text-white font-medium rounded-lg hover:bg-green-600 transition duration-150 shadow-md hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-green-400"
        >
          <MdPlusOne className="text-lg" />
          Add QR Code
        </button>
      </div>

      <Modal
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        contentLabel="Add QR Code Modal"
        className="bg-white rounded-lg shadow-lg w-96 p-6 m-auto outline-none"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
      >
        <h2 className="text-lg font-bold mb-4">Choose QR Code Type</h2>
        <div className="space-y-4">
          <div>
            <label className="flex items-center">
              <input
                type="radio"
                name="qrType"
                value="certificate"
                checked={selectedOption === "certificate"}
                onChange={(event) => {

                  setSelectedOption("certificate")

                }}
                className="mr-2"
              />
              Certificate Verification
            </label>
          </div>
          <div>
            <label className="flex items-center">
              <input
                type="radio"
                name="qrType"
                value="custom"
                checked={selectedOption === "custom"}
                onChange={() => setSelectedOption("custom")}
                className="mr-2"
              />
              Custom Link
            </label>
            {selectedOption === "custom" && (
              <input
                type="text"
                value={customLink}
                onChange={(e) => {
                  setCustomLink(e.target.value);
                  e.preventDefault();
                  e.stopPropagation();
                }}
                placeholder="Enter custom link"
                className="mt-2 w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-400"
              />
            )}
          </div>
        </div>
        <div className="mt-6 flex justify-end space-x-4">
          <button
            onClick={() => setShowModal(false)}
            className="px-4 py-2 bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400 focus:outline-none"
          >
            Cancel
          </button>
          <button
            onClick={handleAddQrCode}
            className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400"
          >
            Add QR Code
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default QrPanel;
