

import React, { useState, useEffect } from 'react';
import { FaTrash, FaCopy } from 'react-icons/fa';
import HeaderComponent from '../Header';
import { LazyLoadImage } from 'react-lazy-load-image-component';


const App = ({ templates }) => {
  const [jsonInput, setJsonInput] = useState('');

  console.log("PROPS ARE", templates)

  // Use a basic example JSON for initial testing
  useEffect(() => {
    const exampleJSON = JSON.stringify({
      version: "4.6.0",
      objects: [
        {
          type: "circle",
          left: 100,
          top: 100,
          radius: 50,
          fill: "red"
        },
        {
          type: "rect",
          left: 200,
          top: 200,
          width: 100,
          height: 100,
          fill: "blue"
        }
      ]
    });
    setJsonInput(exampleJSON); // Set initial JSON for testing
  }, []);



  return (
    <div >
      <HeaderComponent />
      <div className="flex w-full">
        {/* Design Cards Section */}
        <div className="container mx-auto py-8 px-4">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            {templates.map((design) => (
              <a
                key={design.id}
                href={`/authors/templates/${design.id}`} // Rails route
                className="bg-white p-4 shadow-md rounded-md hover:shadow-lg transition-all flex flex-col"
                style={{ width: '100%', textDecoration: 'none' }}
              >
                {/* Image Placeholder */}
                {/* Image Placeholder */}
                <div className="aspect-w-16 aspect-h-9 rounded-t-lg overflow-hidden">
                  <LazyLoadImage
                    alt={design.name}
                    className="w-full h-full object-cover"
                    height={"100%"}
                    width={"100%"}
                    src={design.cover_image ? `${design.cover_image}?q=${design.updated_at}` : "/placeholder.png"}
                  />

                </div>
                {/* Card Content */}
                <div className="mt-4">
                  <h2 className="text-lg font-semibold">{design.name}</h2>

                </div>
              </a>
            ))}
          </div>
        </div>


      </div>
    </div>


  );
};

export default App;

