import { useDebouncedCallback } from 'use-debounce';
import { useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import generateRandomString from '../../utils/generateRandomString';
const fabric = require('fabric');

const useEditor = ({
  editor,
  uploadBg,
  id,
  update,
  setFontFamily,
  setFontColor,
  setFontSize,
  onReady,
  certificate,
  setTextObjects,
  setDynamicTextObjects,
  bgImage,
  fontFamily,
  fontColor,
  fontSize
}) => {
  const canvasRef = useRef(null);

  //custom fields 
  const customFields = [
    'id',
    'name',
    'selectable',
    'evented',
    'dynamic',
    'isQr',
    'qrData',
    'editable'
  ]

  async function fetchBackgroundImage(imageUrl) {
    const image = fabric.FabricImage.fromURL(imageUrl)
    //Go through all the objects and find the image object with id backgroundImage 
    //and remove it from the canvas

    const objects = editor.canvas.getObjects();

    objects.forEach(function (object) {
      if (object.id === "backgroundImage") {
        editor.canvas.remove(object);
      }
    });

    image.then(function (img) {
      img.id = "backgroundImage"
      img.name = "BackgroundImage"
      img.selectable = false
      img.evented = false
      img.width = editor.canvas.width
      img.height = editor.canvas.height

      editor.canvas.add(img)
      editor.canvas.sendObjectToBack(img)

      editor.canvas.renderAll();
      editor.canvas.toObject(customFields);
      editor.canvas.fire('object:modified', { target: img });
    })
  }


  useEffect(() => {

    const handleKeyDown = (event) => {
      if (event.key === 'Escape' || event.keyCode === 27) {
        // Deselect all objects on the canvas
        if (canvasRef.current) {
          canvasRef.current.discardActiveObject();
          canvasRef.current.requestRenderAll();
        }
        event.preventDefault();

      }

      // For moving 
      if (event.key === 'ArrowUp' || event.keyCode === 38) {
        if (canvasRef.current) {
          const activeObject = canvasRef.current.getActiveObject();
          if (!activeObject) return;
          canvasRef.current.getActiveObject().set({
            top: canvasRef.current.getActiveObject().top - 1
          });
          canvasRef.current.renderAll();
        }
      }

      // For moving down
      if (event.key === 'ArrowDown' || event.keyCode === 40) {
        if (canvasRef.current) {
          const activeObject = canvasRef.current.getActiveObject();
          if (!activeObject) return;
          canvasRef.current.getActiveObject().set({
            top: canvasRef.current.getActiveObject().top + 1
          });
          canvasRef.current.renderAll();
        }
      }
      // For moving left
      if (event.key === 'ArrowLeft' || event.keyCode === 37) {
        if (canvasRef.current) {
          const activeObject = canvasRef.current.getActiveObject();
          if (!activeObject) return;
          canvasRef.current.getActiveObject().set({
            left: canvasRef.current.getActiveObject().left - 1
          });
          canvasRef.current.renderAll();
        }
      }

      // For moving right
      if (event.key === 'ArrowRight' || event.keyCode === 39) {
        if (canvasRef.current) {
          const activeObject = canvasRef.current.getActiveObject();
          if (!activeObject) return;
          canvasRef.current.getActiveObject().set({
            left: canvasRef.current.getActiveObject().left + 1
          });
          canvasRef.current.renderAll();
        }
      }

      //Select all objects
      if (event.key === 'a' && event.ctrlKey) {
        if (canvasRef.current) {
          canvasRef.current.discardActiveObject();
          canvasRef.current.requestRenderAll();
        }
      }
    };

    // Attach keydown event listener
    document.addEventListener('keydown', handleKeyDown);

    // Cleanup event listener on unmount
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);


  useEffect(() => {
    if (editor) {
      canvasRef.current = editor.canvas;

      //Check the current object and update the font size and family
      //Go through the objects and find the text object with id textObject
      //and update the font size and family
      const objects = editor.canvas.getObjects().filter(obj => {
        return obj.type === 'i-text' && obj.dynamic != true;
      });
      const dynamicTexts = editor.canvas.getObjects().filter(obj => {
        return obj.type === 'i-text' && obj.dynamic === true;
      });
      setTextObjects([...objects]);
      setDynamicTextObjects([...dynamicTexts]);
    }
  }, [editor && editor.canvas]);



  useEffect(() => {
    if (editor) {
      //Check the current object and update the font size and family
      //Go through the objects and find the text object with id textObject
      //and update the font size and family
      const object = editor.canvas.getActiveObject()
      if (object && object.type === 'i-text') {

        //Check if the object is a text object
        object.set({ fontFamily: fontFamily.label });
        editor.canvas.renderAll();
        editor.canvas.fire('object:modified', { target: null });

      }
    }
  }, [fontFamily]);


  useEffect(() => {
    if (editor) {
      //Check the current object and update the font size and family
      //Go through the objects and find the text object with id textObject
      //and update the font size and family
      const objects = editor.canvas.getActiveObjects()


      //If no objects then it should be the canvas
      if (objects.length === 0) {
        editor.canvas.backgroundColor = fontColor;
        editor.canvas.renderAll();
        editor.canvas.fire('object:modified', { target: null });

        return;
      }
      //Check if the object is a text object\

      objects.forEach(object => {
        object.set({ fill: fontColor });
        object.set({ stroke: fontColor });
        //Check if the object is svg 
        if (object.type === "group") {
          const objects = object.getObjects();
          objects.forEach(obj => {
            if (obj.type === 'path') {
              obj.set({
                fill: fontColor, // Set your desired fill color
              });
            }
            //if object is a group

          });

        }
        editor.canvas.renderAll();
      });

      editor.canvas.fire('object:modified', { target: null });


    }
  }, [fontColor]);

  useEffect(() => {
    if (editor) {
      //Check the current object and update the font size and family
      //Go through the objects and find the text object with id textObject
      //and update the font size and family
      const object = editor.canvas.getActiveObject()
      if (object && object.type === 'i-text') {
        object.set({ fontSize: fontSize });

        //Check if the object is a text object
        editor.canvas.renderAll();
      }
    }
  }, [fontSize]);

  //Add the background image to the canvas
  useEffect(() => {
    if (bgImage) {
      fetchBackgroundImage(bgImage);
    }
  }, [bgImage]);

  const debounced = useDebouncedCallback(
    // function
    ({ id, data }) => {
      //Check the current object and update the font size and family
      //Go through the objects and check if id and name field exists. If they do not exist, add them (use random string)
      const objects = data.objects.map((object) => {
        if (!object.id) {
          object.id = generateRandomString(8);
        }
        if (!object.name) {
          object.name = generateRandomString(8);
        }
        return object;
      });
      data.objects = objects;

      update({ id, data });
    }, 1000);

  const handleTemplateClick = (template) => {
    loadFromJSON(template.data); // Load the selected template
  };

  const loadFromJSON = async (jsonInput) => {
    //Clear the dynamic text objects
    setDynamicTextObjects([]);
    //Clear the text objects
    setTextObjects([]);
    await editor.canvas.loadFromJSON(jsonInput)
    editor.canvas.renderAll(); // Force render after loading
  };



  const handleOnReady = (canvas) => {
    const A4_WIDTH = 1122; // Convert mm to px
    const A4_HEIGHT = 794;

    onReady(canvas);
    canvas.enableRetinaScaling = false
    canvas.preserveObjectStacking = true;
    canvas.setWidth(A4_WIDTH);
    canvas.setHeight(A4_HEIGHT);
    canvas.backgroundColor = '#F6F6F4';

    canvas.loadFromJSON(certificate.data, () => {
      setTimeout(() => canvas.renderAll(), 50);
    });


    fabric.devicePixelRatio = 1;
    canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);





    canvas.on('mouse:click', (event) => {
      const clickedItem = event.target;
      if (clickedItem && clickedItem instanceof fabric.IText) {
        clickedItem.enterEditing(); // Enter editing mode
        canvas.renderAll();
      }
      // const line = new fabric.Line([50, 100, 250, 100], {
      //   stroke: 'black',      // Line color
      //   strokeWidth: 2,       // Line width
      // })
      // canvas.add(line);
      // canvas.renderAll();

    });

    // addControlWithIcon(signatureText, FaCopy, (eventData, transform) => {
    //   const target = transform.target;
    //   const clone = fabric.util.object.clone(target);
    //   clone.set({ left: target.left + 10, top: target.top + 10 });
    //   canvas.add(clone);
    //   canvas.requestRenderAll();
    // });

    // Example with coordinates within a typical canvas
    ///Take note of when the object is modified
    canvas.on('object:modified', function (event) {
      // Get the modified object
      canvas.calcOffset();
      const data = canvas.toObject(customFields);
      update({
        id: id,
        data
      });
    });


    canvas.on('object:scaling', function (e) {
      const obj = e.target;
      if (obj) {
        debounced({ id, data: canvas.toObject(customFields) });
        // Perform actions based on the resizing, such as adjusting font size instead of scaling
      }
    });
    // Listen for when a new selection is created
    canvas.on('selection:created', (e) => {
      const selectedObjects = e.selected;

      if (selectedObjects && selectedObjects.length === 1) {
        setFontFamily({
          label: selectedObjects[0].fontFamily,
          value: selectedObjects[0].fontFamily
        });
        setFontColor(selectedObjects[0].fill);
        setFontSize(selectedObjects[0].fontSize);

      }
      // Add any additional logic you want to perform when a new object is selected
      const data = canvas.toObject(customFields);
      debounced({ id, data })
      // update({ id, data });
    });

    // Listen for when the selection is updated
    canvas.on('selection:updated', (e) => {
      const selectedObjects = e.selected;


      if (selectedObjects && selectedObjects.length === 1) {
        setFontFamily({
          label: selectedObjects[0].fontFamily,
          value: selectedObjects[0].fontFamily
        });
        setFontSize(selectedObjects[0].fontSize);
        setFontColor(selectedObjects[0].fill);

        const data = canvas.toObject(customFields);
        debounced({ id, data })
      }      // This can be used to detect when additional objects are added to the selection
    });

    //Added a new object to the canvas

    canvas.on('object:added', function (e) {
      const obj = e.target;
      obj.lockUniScaling = true;

      if (obj.type === 'i-text' && obj.dynamic != true) {
        obj.setControlsVisibility({
          mt: false,
          mb: false,
          ml: false,
          mr: false,
          bl: true,
          br: true,
          tl: true,
          tr: true
        })
        setTextObjects((prev) => [...prev, obj]);
      } else if (obj.type === 'i-text' && obj.dynamic === true) {
        obj.setControlsVisibility({
          mt: false,
          mb: false,
          ml: false,
          mr: false,
          bl: true,
          br: true,
          tl: true,
          tr: true
        })
        setDynamicTextObjects((prev) => [...prev, obj]);
      }
      const data = canvas.toObject(customFields);
      debounced({ id, data })

    });


    // Listen for when an object is removed
    canvas.on('object:removed', (e) => {
      const obj = e.target;
      obj.lockUniScaling = true;

      if (obj.type === 'i-text' && obj.dynamic != true) {
        obj.setControlsVisibility({
          mt: false,
          mb: false,
          ml: false,
          mr: false,
          bl: true,
          br: true,
          tl: true,
          tr: true
        })
        //Remove the object from the text objects
        setTextObjects((prev) => {
          return prev.filter((item) => item !== obj);
        });
      } else if (obj.type === 'i-text' && obj.dynamic === true) {
        obj.setControlsVisibility({
          mt: false,
          mb: false,
          ml: false,
          mr: false,
          bl: true,
          br: true,
          tl: true,
          tr: true
        })
        //Remove the object from the dynamic text objects
        setDynamicTextObjects((prev) => {
          return prev.filter((item) => item !== obj);
        }
        );
      }


      const data = canvas.toObject(customFields);
      debounced({ id, data })
    });

  };

  //Handle background color change
  const handleBackgroundChange = ({ color = "#fff" }) => {
    editor.canvas.backgroundColor = color;
    editor.canvas.renderAll();
  };


  //Handle the background image upload
  const handleBackgroundImageUpload = ({ target: { files } }) => {
    const file = files[0];
    if (!file) return; // Early return if no file

    if (!file.type.startsWith("image/")) {
      console.error("Please upload a valid image file.");
      return;
    }

    const reader = new FileReader();

    reader.onload = ({ target: { result } }) => {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("id", id);
      uploadBg(formData);
    };

    reader.onerror = (error) => {
      console.error("Error reading file:", error);
    };

    reader.readAsDataURL(file);
  };

  //Bring the selected object to the backed
  const handleSendToBack = () => {
    if (editor) {
      // Get all the active objects (supports multiple selection)
      const activeObjects = editor.canvas.getActiveObjects();

      if (activeObjects.length > 0) {
        activeObjects.forEach((obj) => {
          // Send each object to the back
          editor.canvas.sendObjectToBack(obj);
        });

        // Deselect all objects after moving them
        editor.canvas.discardActiveObject();
      }

      // Re-render the canvas to reflect changes
      editor.canvas.renderAll();
    }
  };


  //Set text align

  const handleTextAlign = (align) => {
    //Check the current object and update the font size and family
    if (editor) {
      const activeObject = editor.canvas.getActiveObject();
      if (!activeObject) {
        return;
      }
      activeObject.set({ textAlign: align });
      editor.canvas.renderAll();
      editor.canvas.fire('object:modified', { target: activeObject });
    }
  };

  const handleBgSelection = (image) => {
    if (image) {
      const bgimage = fabric.FabricImage.fromURL(image)
      //Go through all the objects and find the image object with id backgroundImage 
      //and remove it from the canvas

      const objects = editor.canvas.getObjects();

      objects.forEach(function (object) {
        if (object.id === "backgroundImage") {
          editor.canvas.remove(object);
        }
      });

      bgimage.then(function (img) {
        img.id = "backgroundImage"
        img.selectable = false
        img.evented = false
        img.width = editor.canvas.width
        img.height = editor.canvas.height


        editor.canvas.add(img)
        editor.canvas.sendObjectToBack(img)
        editor.canvas.toObject(customFields);
        editor.canvas.fire('object:modified', { target: img });



      })
      editor.canvas.renderAll();
    }
  }

  //Handle rotate right
  const handleRotateRight = () => {
    //Check the current object and update the font size and family
    if (editor) {
      const activeObject = editor.canvas.getActiveObject();
      activeObject.rotate(activeObject.angle + 90);
      editor.canvas.renderAll();
      editor.canvas.fire('object:modified', { target: activeObject });

    }
  };

  //Handle rotate left
  const handleRotateLeft = () => {
    //Check the current object and update the font size and family
    if (editor) {
      const activeObject = editor.canvas.getActiveObject();
      //Rotate the object to the left
      activeObject.rotate(activeObject.angle - 90);
      editor.canvas.renderAll();
      editor.canvas.fire('object:modified', { target: activeObject });

    }
  };

  const handleBringToFront = (index) => {
    //Check the current object and update the font size and family
    if (editor) {
      const activeObject = editor.canvas.getActiveObject();
      editor.canvas.bringObjectForward(activeObject)
      editor.canvas.renderAll();
      editor.canvas.fire('object:modified', { target: activeObject });

    }
  };

  //Delete selected object
  const handleDelete = (index) => {
    //Check the current object and update the font size and family
    if (editor) {
      const activeObject = editor.canvas.getActiveObject();
      editor.canvas.remove(activeObject);
      editor.canvas.renderAll();
      editor.canvas.fire('object:modified', { target: activeObject });

    }

  };

  const handleCopy = async () => {
    //Check the current object and update the font size and family
    const randomString = generateRandomString(8);

    const activeObject = editor.canvas.getActiveObject()
    if (!activeObject) {
      toast("No object selected to copy", {
        type: "error",
        autoClose: 2000,
        position: "bottom-center"
      });
      return;
    }
    if (activeObject.dynamic) {
      toast("Dynamic text cannot be copied", {
        type: "error",
        autoClose: 2000,
        position: "bottom-center"

      })
      return;
    }
    const cloneObject = await activeObject.clone()
    cloneObject.set({
      left: activeObject.left + 10,
      top: activeObject.top + 10,
      name: `${randomString}`,
      dynamic: activeObject.dynamic,
    });
    editor.canvas.add(cloneObject)
    editor.canvas.setActiveObject(cloneObject)
    editor.canvas.fire('object:modified', { target: cloneObject });

  }



  //add svg image 
  const addSvgImage = async (svg) => {
    const { objects, options } = await fabric.loadSVGFromURL(svg.image)
    const svgGraphic = fabric.util.groupSVGElements(objects, options);

    //Get the index 
    const predictedIndex = editor.canvas.getObjects().length;


    svgGraphic.set('fill', '#000000'); // Hex code
    svgGraphic.set('name', `${svg.name}-${predictedIndex}`);
    editor.canvas.add(svgGraphic);
    editor.canvas.centerObject(svgGraphic);
    editor.canvas.fire('object:modified', { target: svgGraphic });
  }


  //Send the selected object to the front
  const handleSendToBackwards = (index) => {
    //Check the current object and update the font size and family
    if (editor) {
      const activeObject = editor.canvas.getActiveObject();
      const objects = editor.canvas.getObjects();
      const isImage = objects[0]; // Assume the image is always the first object
      // Only send to back if the object is not already the lowest non-image item
      if (isImage.type === "image") {
        editor.canvas.sendObjectBackwards(activeObject);
        editor.canvas.renderAll();
      } else {
        editor.canvas.sendObjectBackwards(activeObject);
        editor.canvas.renderAll();
      }
      editor.canvas.fire('object:modified', { target: activeObject });

    }

  };
  //Add static text to the canvas
  const addText = (text) => {
    //Unselect all objects
    editor.canvas.discardActiveObject();

    const textObject = new fabric.IText(text, {
      fontFamily: "Roboto",
      fontSize: 30,
      fill: "#000000",
    });

    textObject.setControlsVisibility({
      mt: false, // middle top
      mb: false, // middle bottom
      ml: false, // middle left
      mr: false, // middle right
      bl: true,  // bottom left
      br: true,  // bottom right
      tl: true,  // top left
      tr: true   // top right
    });
    editor.canvas.calcOffset();
    editor.canvas.centerObject(textObject);
    editor.canvas.add(textObject);
    editor.canvas.renderAll();
    editor.canvas.fire('object:modified', { target: textObject });

  }



  //Add dynamic text to the canvas
  const addDynamicText = (name) => {
    //Name must be a valid variable name
    if (name === "") {
      alert("Please enter a valid variable name")
      return;
    }
    //Unselect all objects
    editor.canvas.discardActiveObject();

    const textObject = new fabric.IText(`[${name}]`, {
      fontFamily: "Roboto",
      fontSize: 30,
      fill: "#000000",
      dynamic: true,
      fieldName: name,
      id: name,
      editable: false
    });

    textObject.setControlsVisibility({
      mt: false, // middle top
      mb: false, // middle bottom
      ml: false, // middle left
      mr: false, // middle right
      bl: true,  // bottom left
      br: true,  // bottom right
      tl: true,  // top left
      tr: true   // top right
    });
    if (editor) {
      editor.canvas.calcOffset();
      editor.canvas.centerObject(textObject);
      editor.canvas.add(textObject);
    }
    editor.canvas.fire('object:modified', { target: textObject });

  }

  return {
    addDynamicText,
    addText,
    handleSendToBack,
    handleSendToBackwards,
    handleBringToFront,
    handleRotateRight,
    handleRotateLeft,
    handleDelete,
    handleCopy,
    addSvgImage,
    handleBgSelection,
    handleBackgroundImageUpload,
    handleOnReady,
    handleTemplateClick,
    handleBackgroundChange,
    handleTextAlign
  }
}

export default useEditor;