import React from "react";
import axios from "axios";
const useLogin = () => {
  const signOut = () => {
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');



    axios.delete("/admins/sign_out", {
      headers: {
        "X-CSRF-Token": csrfToken,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      withCredentials: true,
    }).then((response) => {
      if (response.status === 204) {
        window.location.href = "/admins";
      }
    })
  }
  return { signOut };
}

export default useLogin;