// src/components/Sidebar.js

import React, { useState } from 'react';
import { MdBrush } from 'react-icons/md';
import { AiOutlineBorder } from 'react-icons/ai';
import { FiEdit, FiQr } from 'react-icons/fi';
import { GoTools } from 'react-icons/go';
import { BsQrCode } from "react-icons/bs";

import { MdInsertPhoto } from "react-icons/md";
import TemplatesPanel from './TemplatesPanel';
import FramePanel from './FramePanel';
import GraphicsPanel from './GraphicsPanel';
import TextPanel from './TextPanel';
import SmartTextPanel from './SmartTextPanel';
import SettingsPanel from './SettingsPanel';
import QrPanel from './QrPanel';
const Sidebar = ({
  activeMenu,
  setActiveMenu,
  templates,
  handleTemplateClick,
  handleBgSelection,
  handleBackgroundImageUpload,
  handleBackgroundChange,
  addSvgImage,
  textObjects,
  addText,
  dynamicTextObjects,
  addDynamicText,
  dynamicText,
  setDynamicText,
  paperSize,
  setPaperSize,
  orientation,
  setOrientation,
  editor,
  uploadImage,
  hideTemplates = false,
  bgImage,
}) => {
  const menuItems = [
    { name: 'Templates', icon: <MdBrush /> },
    { name: 'Graphics', icon: <MdInsertPhoto /> },
    { name: 'Text', icon: <FiEdit /> },
    { name: 'Qr Code', icon: <BsQrCode /> },
    // { name: 'Settings', icon: <FiSettings /> },
  ];



  if (hideTemplates) {
    menuItems.shift();
  }


  return (
    <div className="w-64 p-4 border-r border-gray-300 bg-gray-100">
      <ul className="space-y-4">
        {menuItems.map((menuItem) => (
          <li key={menuItem.name}>
            <button
              className={`block w-full px-3 py-2 text-left flex items-center gap-2 rounded-md transition-all ${activeMenu === menuItem.name ? 'bg-blue-500 text-white' : 'bg-white text-gray-700 hover:bg-gray-200'
                }`}
              onClick={() => setActiveMenu(menuItem.name)}
            >
              <span>{menuItem.icon}</span> {menuItem.name}
            </button>
            {activeMenu === menuItem.name && menuItem.name === 'Templates' && (
              <TemplatesPanel templates={templates} handleTemplateClick={handleTemplateClick} />
            )}
            {activeMenu === menuItem.name && menuItem.name === 'Frame' && (
              <FramePanel
                handleBgSelection={handleBgSelection}
                handleBackgroundImageUpload={handleBackgroundImageUpload}
                handleBackgroundChange={handleBackgroundChange}
                bgImage={bgImage}
                key={bgImage}
              />
            )}
            {activeMenu === menuItem.name && menuItem.name === 'Graphics' && (
              <GraphicsPanel
                uploadImage={uploadImage}
                editor={editor}
                addSvgImage={addSvgImage}
              />
            )}
            {activeMenu === menuItem.name && menuItem.name === 'Text' && (
              <TextPanel editor={editor} textObjects={textObjects} addText={addText} />
            )}

            {activeMenu === menuItem.name && menuItem.name === 'Qr Code' && (
              <QrPanel editor={editor} textObjects={textObjects} addText={addText} />
            )}

            {/* {activeMenu === menuItem.name && menuItem.name === 'Dynamic Fields' && (
              <SmartTextPanel
                dynamicTextObjects={dynamicTextObjects}
                addDynamicText={addDynamicText}
                dynamicText={dynamicText}
                setDynamicText={setDynamicText}
              />
            )} */}
            {/* {activeMenu === menuItem.name && menuItem.name === 'Settings' && (
              <SettingsPanel
                paperSize={paperSize}
                setPaperSize={setPaperSize}
                orientation={orientation}
                setOrientation={setOrientation}
              />
            )} */}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;
