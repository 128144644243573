import React, { useState } from "react";
import useRecipients from "../hooks/useRecipients";
import TopHeaderComponent from "../../TopHeaderComponent";

const NewRecipient = ({ fields = [], id }) => {
  const [errors, setErrors] = useState({});
  const { create } = useRecipients();
  const [email, setEmail] = useState("");
  const [data, setData] = useState({});
  const [send_email, setSendEmail] = useState(false);
  const [email_subject, setEmailSubject] = useState("");
  const [email_body, setEmailBody] = useState("");

  // Basic Email Validation
  const isValidEmail = (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);

    // Reset send email toggle and fields when email changes
    if (!isValidEmail(value)) {
      setSendEmail(false);
      setEmailSubject("");
      setEmailBody("");
    }
  };

  const handleCreateRecipient = (e) => {
    create({
      email,
      data,
      id,
      send_email,
      email_subject,
      email_body
    });
    e.preventDefault();
  };

  return (
    <>
      <TopHeaderComponent />
      <div className="flex justify-center mt-10 px-4 sm:px-0">
        <div className="bg-white shadow-md rounded-lg p-6 w-full max-w-lg">
          <h2 className="text-2xl font-bold text-gray-800 mb-6 text-center">
            Add a New Recipient
          </h2>
          <form onSubmit={handleCreateRecipient} className="space-y-6">
            {/* Email Input */}
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email (Optional)
              </label>
              <input
                id="email"
                type="email"
                value={email}
                onChange={handleEmailChange}
                placeholder="Enter email address"
                className={`mt-1 block w-full p-3 border rounded-md text-sm ${isValidEmail(email)
                  ? "border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                  : "border-red-500 focus:ring-red-500 focus:border-red-500"
                  }`}
                aria-label="Email"
              />
              {/* Toggle Send Email */}
              {isValidEmail(email) && (
                <div className="flex items-center justify-between mt-4">
                  <span className="text-sm text-gray-700">Send Email</span>
                  <label className="relative inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      checked={send_email}
                      onChange={() => setSendEmail((prev) => !prev)}
                      className="sr-only peer"
                    />
                    <div
                      className={`w-11 h-6 bg-gray-300 rounded-full peer-focus:ring-4 peer-focus:ring-blue-300 
                      ${send_email ? "peer-checked:bg-blue-600" : ""}`}
                    ></div>
                    <span
                      className={`absolute left-1 top-1 w-4 h-4 bg-white rounded-full transition-transform ${send_email ? "translate-x-5" : ""
                        }`}
                    ></span>
                  </label>
                </div>
              )}
            </div>

            {/* Email Subject and Body */}
            {send_email && (
              <div className="space-y-4 mt-4">
                <div>
                  <label htmlFor="email_subject" className="block text-sm font-medium text-gray-700">
                    Email Subject
                  </label>
                  <input
                    id="email_subject"
                    type="text"
                    value={email_subject}
                    onChange={(e) => setEmailSubject(e.target.value)}
                    placeholder="Enter email subject"
                    className="mt-1 
                    block 
                    w-full 
                    p-3 
                    border 
                    border-gray-300 
                    rounded-md 
                    focus:ring-blue-500 
                    focus:border-blue-500 text-sm"
                  />
                </div>
                <div>
                  <label htmlFor="email_body" className="block text-sm font-medium text-gray-700">
                    Email Body
                  </label>
                  <textarea
                    id="email_body"
                    rows="4"
                    value={email_body}
                    onChange={(e) => setEmailBody(e.target.value)}
                    placeholder="Enter email body"
                    className="mt-1 
                    block 
                    w-full 
                    p-3 
                    border 
                    border-gray-300 
                    rounded-md 
                    focus:ring-blue-500 
                    focus:border-blue-500 
                    text-sm"
                  ></textarea>
                </div>
              </div>
            )}

            {/* Dynamic Fields */}
            {fields.map((field, index) => (
              <div key={index}>
                <label htmlFor={field} className="block text-sm font-medium text-gray-700">
                  {field}
                </label>
                <input
                  id={field}
                  type="text"
                  value={data[field] || ""}
                  onChange={(e) => setData({ ...data, [field]: e.target.value })}
                  placeholder={`Enter ${field}`}
                  className="mt-1 block w-full p-3 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 text-sm"
                />
              </div>
            ))}

            {/* Buttons */}
            <div className="flex justify-between space-x-4">
              <button
                type="submit"
                className="w-full py-2 px-4 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition duration-200"
              >
                Create Recipient
              </button>
              <button
                type="button"
                onClick={() => window.history.back()}
                className="w-full py-2 px-4 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 transition duration-200"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default NewRecipient;
