import React, { useState } from "react";

const CheckableRadio = () => {
  const [isChecked, setIsChecked] = useState(false);

  const handleRadioClick = () => {
    setIsChecked((prev) => !prev); // Toggle state on click
  };

  return (
    <div className="flex items-center space-x-3">
      <label htmlFor="custom-radio" className="flex items-center space-x-2">
        <input
          type="radio"
          id="custom-radio"
          name="custom-radio"
          checked={isChecked}
          onClick={handleRadioClick}
          readOnly
          className="h-5 w-5 text-blue-600 border-gray-300 focus:ring-blue-500"
        />
        <span className="text-gray-700">Checkable Radio</span>
      </label>
    </div>
  );
};

export default CheckableRadio;
